import React, { Component, PropTypes } from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import { Switch } from 'react-router';
// import ga from 'react-ga';
import config from '../../config'
import TitlePage from '../TitlePage';
import ProjectPage from '../ProjectPage';
import './style.scss';

class App extends Component {

	constructor() {
		super();

		// ga.initialize(config.googleAnalyticsId, { debug: false });

		// browserHistory.listen(function(event) {
		// 	ga.pageview(event.pathname);
		// });
	}

	render() {
		return (
			<BrowserRouter>
				<Switch>
					<Route exact path="/" component={TitlePage} />
					<Route path="/:project" component={ProjectPage} />
				</Switch>
			</BrowserRouter>
			// <div>
			// 	<Router history={browserHistory}>
			// 		<Route path="/" >
			// 			<IndexRoute component={TitlePage} />
			// 			<Route path=":project" component={ProjectPage} />
			// 		</Route>
			// 	</Router>
			// </div>
		);
	}

}

export default App;
